import React, {useState} from 'react';
import { Container2, Logo2,  Header } from './styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from '../../../services/api'
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Styles from '../../../components/Styles';
import { getUser, getusertype} from "../../../services/auth";
import { Form } from 'react-bootstrap';

import logo_icon from '../../../img/icon.png';

import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ModalAdd from './ModalAdd';

import "tabler-react/dist/Tabler.css";
import { Table, Badge } from "tabler-react"; 
import { CSVLink } from "react-csv";
import Plano from './Plano';





function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Body({empresas, contratos, filiais}) {
    const classes = Styles();
    const [columns, setColumns] = useState(null);
    const [products, setProducts] = useState(null);
    const [lista, setLista] = useState(false);
    const [open, setOpen] = useState(false);
    const [exportCsv, setExportCsv] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => {
        setOpen(false);
      };
    const handleToggle = () => {
        setOpen(!open);
    };
    function consultar(){
        for(var dados in state) {
            if(state[dados] === ""){
                setOpen3({
                    show: true,
                    text: 'Preencha todos os campos para consultar!',
                    type: 'warning'
                });
                return false
            }
        }
       
        handleToggle();
        var {empresa, contrato, filial} = state;
        api.get('/skap/resultado',
            { 
                headers: { 
                        empresa, 
                        contrato,
                        filial,
                        user: getUser(),
                    } 
                }
            )
        .then(function (response) {
            var {data} = response.data;
            var json = data;
            data = JSON.stringify(json);
            data = JSON.parse(data);
            if(json !== null && json !== '' && json !== '[]'){
                let where2 = [];
                json.forEach(dados => {
                    for(var colunas in dados) {
                        let o2 = { 
                            dataField: colunas,
                            text: colunas, 
                        };
                        where2.push(o2);
                    }
                });
                var uniques = [];
                var itemsFound = {};
                for(var i = 0, l = where2.length; i < l; i++) {
                    var stringified = JSON.stringify(where2[i]);
                    if(itemsFound[stringified]) { continue; }
                    uniques.push(where2[i]);
                    itemsFound[stringified] = true;
                }
                
                setColumns(uniques);
                setProducts(json);
                
                var export_temp = data.map((column)  => {
                    delete column.id;
                    var name = column.text;
                    if(name === "status"){

                    }
                    return column;
                })
                setExportCsv(export_temp);
                setLista(true);
            }else{
                setLista(false);
                setProducts(null);
            }
            handleClose();
        });
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                {children}
              </Box>
            )}
          </div>
        );
    }
    function a11yProps(index) {
        return {
          id: `scrollable-force-tab-${index}`,
          'aria-controls': `scrollable-force-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [state, setState] = useState({
        empresa: empresas[0].id,
        contrato: contratos[0].id,
        filial: filiais[0].id,
    });
    const handleChange2 = (event) => {
        const name = event.target.name;
        setState({
          ...state,
          [name]: event.target.value,
        });
    };

    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'warning'
    });
    const handleClose3 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen3({
            show: false,
            text: '',
            type: 'warning'
        });
    };
    const type = getusertype();
    return (
    <Container2>
        <Header>
            Skap
        </Header>
        <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        aria-label="scrollable force tabs example"
        >
            <Tab label="Resultado"  {...a11yProps(0)} />
            <Tab label="Plano"  {...a11yProps(1)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Form>
                <Grid container spacing={3}>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_empresa">Empresa</InputLabel>
                        <Select
                        native
                        value={state.empresa}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'empresa',
                            id: 'abast_empresa',
                        }}
                        >
                            {empresas.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                            
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_contrato">Contrato</InputLabel>
                        <Select
                        native
                        value={state.contrato}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'contrato',
                            id: 'abast_contrato',
                        }}
                        >
                            {contratos.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <InputLabel className={classes.linha} htmlFor="abast_filial">Filial</InputLabel>
                        <Select
                        native
                        value={state.filial}
                        onChange={handleChange2}
                        className={classes.textField}
                        inputProps={{
                            name: 'filial',
                            id: 'abast_filial',
                        }}
                        >
                            {filiais.map((dados, index) => (
                                <option key={dados.id} value={dados.id}>{dados.descr}</option>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Consultar">
                            <IconButton aria-label="Consultar" onClick={() => consultar()} color="primary" variant="contained">
                                <SearchIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <CSVLink data={exportCsv} filename={"relatorio.csv"}>     
                            <Tooltip title="Exportar csv">
                                <IconButton aria-label="Exportar csv" variant="contained">
                                    <FontAwesomeIcon icon={faFileCsv} size="lg"/>
                                </IconButton>
                            </Tooltip>
                        </CSVLink>
                    </Grid> 
                    {type === "a" && (
                        <Grid item xs="auto">
                            <Tooltip title="Importar resultado">
                                <IconButton  aria-label="Importar resultado" onClick={() => setModalShow(true)} variant="contained" color="primary">
                                    <AddCircleIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Form>
            <Box component="span" m={1}>
            {open ? (
                <Logo2>
                    <img src={logo_icon} alt="" />
                    <CircularProgress />
                </Logo2>
            ) : (
                <>
                    {lista && (
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {columns.map((dados, index) => {
                                        if(dados.text !== "id"){
                                            return (<Table.ColHeader key={dados.text}>{dados.text}</Table.ColHeader>);
                                        }
                                        return false;
                                    })}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {products.map((dados, index) => (
                                    <Table.Row key={dados.id}>
                                        {columns.map((column, index) => {
                                            var name = column.text;
                                            if(name !== "id"){
                                                if(name === "Pagamento"){
                                                    if(dados[name] === 'Pago'){
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                            <Badge color="success" className="mr-1">
                                                                {dados[name]}
                                                            </Badge>
                                                        </Table.Col>);
                                                    }else{
                                                        if(dados[name] === 'Lançado'){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                <Badge color="warning" className="mr-1">
                                                                    {dados[name]}
                                                                </Badge>
                                                            </Table.Col>);
                                                        }else{
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {dados[name]}
                                                            </Table.Col>);
                                                        }
                                                    }
                                                }else{
                                                    if(name === "Emitido"){
                                                        if(dados[name] === 'Sim'){
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                <Badge color="success" className="mr-1">
                                                                    {dados[name]}
                                                                </Badge>
                                                            </Table.Col>);
                                                        }else{
                                                            return (<Table.Col className={classes.linha} key={dados.id + column.text}>
                                                                {dados[name]}
                                                            </Table.Col>);
                                                        }
                                                    }else{
                                                        return (<Table.Col className={classes.linha} key={dados.id + column.text}>{dados[name]}</Table.Col>);
                                                    }
                                                }
                                            }
                                            return false;
                                        })}
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    )}
                </>
            )}
            </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Plano empresas={empresas} contratos={contratos} filiais={filiais} type={type} />
        </TabPanel>
        {modalShow && (
            <ModalAdd
                open={modalShow}
                onClose={() => {
                    setModalShow(false);
                    consultar();
                }}
                maxWidth="sm"
                fullWidth={true}
                empresa={state.empresa}
                contrato={state.contrato}
                filial={state.filial}
            />
        )}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    
    </Container2>
    );
}

export default Body;