import React, {useState, useRef} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Form } from 'react-bootstrap';
import api from '../../../../services/api'
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import DialogTitle from '../../../../components/BoardGas/Components/DialogTitle';
import CustomizedProgressBars from '../../../Treinamentos/components/ModalAdd/components/CustomizedProgressBars'

import { getUser} from "../../../../services/auth";

import Style from '../../../../components/Styles'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function ModalAdd(props) {
    const classes = Style();
    let uploadObj = useRef(UploaderComponent);
    var {empresa, contrato, filial, onClose, ...rest} = props;
    const [open3, setOpen3] = useState({
        show: false,
        text: '',
        type: 'info',
    });
    const handleClose3 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
        setOpen3({
            show: false,
            text: '',
            type: 'info'
        });
    };
    const [open2, setOpen2] = useState(false);
    const [progress, setProgress] = useState(0);
    function salva(){
        const filesData = uploadObj.getFilesData();
        // eslint-disable-next-line eqeqeq
        if(filesData.length == 0){
            setOpen3({
                show: true,
                text: "Selecione um arquivo!",
                type: 'error'
            });
            return false;
        }
        
        setOpen2(true);
        var formData = new FormData();
        formData.append('file', filesData[0].rawFile);
        formData.append('empresa', empresa);
		formData.append('contrato', contrato);
		formData.append('filial', filial);
        formData.append('user', getUser());
        var config = {
            onUploadProgress: function(progressEvent) {
				var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                setProgress(percentCompleted);
            }
		};
        api.post('/financeiro/faturamento/upload',
        formData,
        config )
        .then(function(response){
            console.log(response.data);
            var {status, message} = response.data;
            if(status === true){
                if(message != null){
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
                onClose();
            }else{
                if(message != null){
                    setOpen3({
                        show: true,
                        text: message,
                        type: "error"
                    });
                }
                setOpen2(false);
            }
        }).catch((error) => {
            console.log(error);
            setOpen3({
                show: true,
                text: 'Ocorreu um erro!',
                type: "error"
            });
            setOpen2(false);
        })
    }
    let onFileSelected = (args) => {
        let existingFiles = uploadObj.getFilesData();
        const filesData = uploadObj.getFilesData();
        if(filesData.length > 0){
            if(args.filesData[0].name !== filesData[0].name){
                uploadObj.filesData.splice(0, 1);
            }
        }
        existingFiles = existingFiles.concat(args.filesData);
        args.modifiedFilesData = existingFiles;
        args.isModified = true;
        
    }
  return (
    <Dialog
        {...rest}
        aria-labelledby="form-dialog-title"
        >
        <DialogTitle onClose={onClose} open4={false}>Importar Faturamento</DialogTitle>
        <DialogContent className={classes.root}>
            <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Form.Group className={classes.disabled}> 
                        <UploaderComponent 
                        locale='pt-PT' 
                        id="myfilefaturamento" 
                        type = 'file'
                        ref = {upload => { uploadObj = upload; }}
                        selected={onFileSelected = onFileSelected.bind(this)}
                        allowedExtensions='.csv'
                        maxFileSize= {62914560}
                        />
                    </Form.Group>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={open2} onClick={onClose}>Cancelar</Button>
            <div className={classes.wrapper}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={open2}
                    onClick={salva}
                    >
                    Salvar
                </Button>
                {open2 && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
        </DialogActions>
        {open2 && <CustomizedProgressBars progress={progress} />}
        <Snackbar open={open3.show} autoHideDuration={6000} onClose={handleClose3}>
            <Alert onClose={handleClose3} severity={open3.type}>
                {open3.text}
            </Alert>
        </Snackbar>
    </Dialog>
  );
}

export default ModalAdd;